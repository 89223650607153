<template>
<AuthLayout>
<template #content>
  
  <div class="ConfirmPage">
    
    <div class="ConfirmPage__title">
      {{ match[$route.name].title }}
    </div>
    
    <div class="ConfirmPage__body">
      <Templater
        :match="match"
        @onSubmit="onSubmit"
      />
    </div>
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import { isSuccess } from '@/helpers/http.helper'
import { toast } from '@/helpers/error.helper'
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"
import Templater from "@/ui-kit/templater/Templater"
import test from '@/components/patient/confirm/test.json'
import { mapActions, mapGetters, mapMutations } from "vuex"
import { PatientAgreementService } from '@/services/api_v1/patient'

export default {
  name: "ConfirmPage",
  components: {
    Templater,
    AuthLayout,
  },
  data() {
    return {
      url: null,
    }
  },
  computed: {
    ...mapGetters({
      test: 'PatientPatientStore/getTest',
    }),
    current() {
      return this.match[this.$route.name]
    },
    contras() {
      return this.test ? JSON.parse(this.test.content).find((i) => i.type === "contraindications").text : []
    },
    time() {
      return this.test ? JSON.parse(this.test.content).find((i) => i.type === "time_requirements").text : []
    },
    match() {
      return {
        'patient.confirm.consent': {
          title: 'Consent Form',
          body: [
            {
              id: 1,
              type: "download",
              content: "doc.png",
              params: {
                url: this.url,
              }
            },
            {
              id: 2,
              type: "checkbox",
              content: "I have read and agree"
            }
          ],
          route: { name: 'patient.confirm.contras' },
          button: 'Next',
          type: 'consent_form',
        },
        'patient.confirm.contras': {
          title: 'Contraindications',
          body: this.contras,
          route: { name: 'patient.confirm.time' },
          button: 'Next',
          type: 'contraindications',
        },
        'patient.confirm.time': {
          title: 'Time Requirements',
          body: this.time,
          route: { name: 'patient.confirm.test' },
          button: 'Next',
          type: 'time_requirements',
        },
        'patient.confirm.test': {
          title: 'Test Restrictions',
          body: test,
          route: { name: 'patient.samples' },
          button: 'Done',
          type: 'test_restrictions',
        },
      }
    },
  },
  methods: {
    ...mapActions({
      sendAgreement: 'PatientPatientStore/sendAgreement',
    }),
    ...mapMutations({
      setAgreement: 'PatientPatientStore/setAgreement',
    }),
    async onSubmit() {
      let payload = { barcode: this.test.barcode, type: this.current.type }
      await this.sendAgreement({ payload })
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          this.setAgreement(response.data.data)
          this.$router.push(this.current.route)
        })
        .catch((err) => {
          toast ({ msg: err?.data?.data?.message })
        })
    },
  },
  async mounted() {
    if (this.$route.name === "patient.confirm.consent") {
      await PatientAgreementService.consentForm()
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          this.url = response.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ConfirmPage {
  padding: 0 15px;
  
  &__body {
    padding: 14px 0 24px 0
  }
  
  &__title {
    color: $polarGreen7;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    white-space: break-spaces;
  }
  
}
</style>